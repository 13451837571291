import Block, { BlockContent } from "~/components/Block";
import ButtonArrow from "~/components/ButtonArrow";
import ButtonOutline from "~/components/ButtonOutline";
import LinkButton from "~/components/LinkButton";
import { getCommonSliceProps, resolveLink } from "~/external/prismic";
import connectSlice from "~/lib/connectSlice";

function RichTextSlice(props) {
  const {
    spacingTop = 0,
    spacingBottom = 6,

    backgroundColor,
    color,
    highlightColor,

    buttonText = "",
    buttonType = "Outlined",
    alignItems = "flex-start",

    href,
    hrefAs,
    hrefTarget,
  } = props;

  let buttonComponent = null; // aka. hidden
  switch (buttonType) {
    case "outlined":
      buttonComponent = ButtonOutline;
      break;
    case "arrow":
      buttonComponent = ButtonArrow;
      break;
  }

  return (
    <Block
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      backgroundColor={backgroundColor}
      color={color}
    >
      <BlockContent style={{ display: "flex", justifyContent: alignItems }}>
        <LinkButton
          buttonComponent={buttonComponent}
          buttonProps={{ color, backgroundColor, highlightColor }}
          href={href}
          as={hrefAs}
          target={hrefTarget}
          buttonText={buttonText}
          style={{ flex: buttonType === "outlined" ? 1 : "initial" }}
        />
      </BlockContent>
    </Block>
  );
}

export function mapSliceToProps(slice) {
  const { primary, items = [] } = slice;

  // console.log('mapSliceToProps', slice);

  const resolveAlignItems = {
    left: "flex-start",
    center: "center",
    right: "flex-end",
  };

  const [href, hrefAs, hrefTarget] = resolveLink(primary.link);

  const props = {
    ...getCommonSliceProps(slice),

    buttonText: primary.button_text,
    buttonType: primary.button_type && primary.button_type.toLowerCase(),
    alignItems: primary.horizontal_align
      ? resolveAlignItems[primary.horizontal_align.toLowerCase()]
      : "flex-start",
    href,
    hrefAs,
    hrefTarget,
  };

  return props;
}

export default connectSlice(mapSliceToProps)(RichTextSlice);
